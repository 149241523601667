import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import HeroHeader from "../../components/HeroHeader";
import BlogPostCard from "../../components/BlogPost/BlogPostCard";
import OurLocations from "../../sections/OurLocations";
import FAQs from "../../sections/FAQs";

const heroHeaderQuery = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query {
    image: file(relativePath: { eq: "getting-started-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        isVisible: { eq: true }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

export default function BlogPage() {
  const query = useStaticQuery(heroHeaderQuery);

  const comingSoon = (
    <div className="w-full text-center my-8 italic text-xl text-feat-darkgray-500 p-8">
      Blog is coming soon, keep an eye out to read more about our outdoor
      training!
    </div>
  );

  let blogCards = comingSoon;
  if (query?.posts?.edges && query?.posts?.edges.length > 0) {
    blogCards = query?.posts.edges.map(({ node: blog }) => {
      return <BlogPostCard {...blog} />;
    });
  }

  return (
    <Layout>
      <Seo
        title="Blog | FEAT Fitness"
        meta_title="Blog | FEAT Fitness"
        meta_desc="Read up on our latest articles from the FEAT Fitness team."
        slug="blog"
      />
      <main>
        <HeroHeader
          title="Blog at FEAT Fitness"
          background_image={query?.image}
          options={[]}
        />
        <div className="py-4 text-center bg-feat-lightgrey"></div>
        <div className="grid grid-cols-1 mt-4 align-middle mb-6 mx-4">
          {blogCards}
        </div>
        <OurLocations />
        <FAQs />
      </main>
    </Layout>
  );
}
