import React from "react";
import { format } from "date-fns";
import { buildImageObj, getBlogUrl } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import { Link } from "gatsby";
import SanityPortableText from "../SanityPortableText/SanityPortableText";
import Heading from "../Heading";

function BlogPostCard(props) {
  return (
    <Link
      className="relative flex flex-col md:flex-row my-4 mx-auto cursor-pointer max-w-md md:max-w-4xl bg-gray-100"
      to={getBlogUrl(props.slug.current)}
    >
      <div className="flex-1 h-full object-center object-cover">
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .auto("format")
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>

      <div className="flex-1 p-4 flex flex-col justify-between">
        <Heading level={3} className="text-md md:text-2xl">
          {props.title}
        </Heading>
        {props._rawExcerpt && (
          <div className="flex-1">
            <SanityPortableText blocks={props._rawExcerpt} />
          </div>
        )}
        <div className="">
          {format(new Date(props.publishedAt), "MMMM Mo, yyyy")}
        </div>
      </div>
    </Link>
  );
}

export default BlogPostCard;
